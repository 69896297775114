import React from 'react';

import ChatbotThreadSystemMessage from '../ChatbotThreadSystemMessage';

interface LLMResponseProps {
  llmResponse: string | null;
  animateIn?: boolean;
}

export const LLMResponse: React.FC<LLMResponseProps> = ({ llmResponse, animateIn }) => {
  if (!llmResponse) return null;

  return (
    <ChatbotThreadSystemMessage animateIn={animateIn}>
      <div dangerouslySetInnerHTML={{ __html: llmResponse }} />
    </ChatbotThreadSystemMessage>
  );
};
