import { ConsumablesOrder } from 'shared/types';

export const getOrderStatus = (order: ConsumablesOrder) => {
  if (!!order.task_completed_at) {
    return 'delivered';
  }

  if (!!order.task_id) {
    return 'locked';
  }

  return 'open';
};
