import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { showSnackbar } from 'helpers/snackbar';

import { media } from 'context';
import { RequestState } from 'ds';
import { AI_ASSISTANT_PATH } from 'routes';
import { actions } from 'store/AIAssistant';
import { selectChatbotThreadById, selectThreadInputs } from 'store/AIAssistant/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ChatbotThreadInput from './ChatbotThreadInput';
import { determineIntent, startThread, updateIntent } from './requests';
import { ChatbotThreadAPI } from './types';

interface Props {
  threadId?: number;
  onCreate?: (chatbotThread: ChatbotThreadAPI) => void;
  setIsExitAnimationActive?: (isExitAnimationActive: boolean) => void;
  isExitAnimationActive?: boolean;
  onThreadInputFocus?: () => void;
}

const CustomerChatbotThreadInput: React.FC<Props> = ({
  threadId,
  onCreate,
  setIsExitAnimationActive,
  isExitAnimationActive,
  onThreadInputFocus
}) => {
  const user = useAppSelector(selectUser);
  const thread = useAppSelector(selectChatbotThreadById(threadId || -1));
  const { isMobile } = useContext(media);
  const parsedThreadId = threadId || -1;
  const disableAnimation = !!thread?.chatbot_interactions.length;
  const [requestState, setRequestState] = useState<RequestState>('ready');
  const lastInteraction = thread?.chatbot_interactions.slice(-1)[0];
  const threadInputs = useAppSelector(selectThreadInputs);
  const threadInput = threadInputs.find(i => i.threadId === parsedThreadId);
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const setInput = (input: string) => {
    dispatch(actions.setThreadInput({ input, threadId: parsedThreadId }));
  };

  if (!user) return null;

  const isConversationMode = !!lastInteraction?.llm_conversation && !lastInteraction.resolved_at;

  const handleInteraction = async ({ threadId, input }: { threadId: number; input: string }) => {
    if (isMobile) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'instant'
      });
    } else {
      const chatContainer = document.querySelector('#chatbot-thread-container');
      if (chatContainer) {
        setTimeout(() => {
          chatContainer.scrollTop = chatContainer.scrollHeight;
        }, 100);
      }
    }

    if (isConversationMode && !lastInteraction.resolved_at) {
      const firstUnresolvedIntent = lastInteraction.chatbot_intents.find(intent => !intent.resolved_at);
      // TODO: what if the intent they are working with is not the first unresolved intent?
      const { data: convoInteraction } = await updateIntent({
        chatbot_interaction_id: lastInteraction.id,
        slots: { ...firstUnresolvedIntent?.slots, message: input }
      });
      dispatch(actions.removePendingThreadInteraction({ threadId }));
      dispatch(actions.updateInteraction({ interaction: convoInteraction, threadId: threadId }));
    } else {
      const { data } = await determineIntent({
        chatbotThreadId: threadId,
        message: input,
        intentGroup: 'tenancy_customers'
      });
      dispatch(actions.removePendingThreadInteraction({ threadId }));
      dispatch(actions.addInteraction({ interaction: data, threadId }));
    }

    if (isMobile) {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'instant'
      });
    }
  };

  const handleSubmit = async () => {
    if (!threadInput || !threadInput.input || requestState === 'in_progress') return;
    setIsExitAnimationActive?.(true);
    setRequestState('in_progress');

    try {
      if (thread) {
        dispatch(actions.addPendingThreadInteraction({ threadId: thread.id, input: threadInput.input }));
        setInput('');
        await handleInteraction({ threadId: thread.id, input: threadInput.input });
      } else {
        const { data } = await startThread();
        onCreate?.(data);
        dispatch(actions.addThread(data));
        dispatch(actions.addPendingThreadInteraction({ threadId: data.id, input: threadInput.input }));
        setInput('');
        handleInteraction({ threadId: data.id, input: threadInput.input });
        setTimeout(() => {
          history.push(`${AI_ASSISTANT_PATH}/${data.id}`);
          setIsExitAnimationActive?.(false);
        }, 700);
      }
    } catch (error) {
      console.error(error);
      showSnackbar({ negative: true });
    } finally {
      setRequestState('ready');
    }
  };

  return (
    <ChatbotThreadInput
      isExitAnimationActive={isExitAnimationActive}
      input={threadInput?.input || ''}
      onChange={input => setInput(input)}
      handleSubmit={handleSubmit}
      disabled={requestState === 'in_progress'}
      ref={inputRef}
      disableAnimation={disableAnimation}
      onFocus={() => {
        onThreadInputFocus?.();
      }}
    />
  );
};

export default CustomerChatbotThreadInput;
