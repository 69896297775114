import { useContext } from 'react';

import { media } from 'context';
import { Layout, ProductImg, Text } from 'ds';
import AddQuantity from 'ds/AddQuantity';
import TextButton from 'ds/TextButton';
import { formatUsd } from 'helpers';
import { ConsumablesProduct } from 'shared';

interface ConsumablesProductQuantityCardProps {
  product: ConsumablesProduct;
  quantity: number;
  onQuantityChange?: (quantity: number) => void;
  isSaved?: boolean;
  removeItem?: () => void;
  showQuantitySelector?: boolean;
  vendorTask?: boolean;
}
const ConsumablesProductQuantityCard = ({
  product,
  quantity,
  onQuantityChange,
  isSaved,
  removeItem,
  showQuantitySelector = true,
  vendorTask = false
}: ConsumablesProductQuantityCardProps) => {
  const { isMobile } = useContext(media);
  const { amount_cents, unit_type, units_per_package, package_type, brand, detail } = product;
  const formattedAmount = formatUsd(amount_cents / 100);
  const totalPrice = quantity * amount_cents;
  return (
    <Layout flex direction="row" width="100%" align="center">
      <ProductImg
        srcKey={product.image_key}
        size="sm"
        alt={detail}
        {...(isMobile
          ? { style: { width: 48, height: 48, marginRight: 12 } }
          : { style: { width: 72, height: 72, marginRight: 16 } })}
      />
      <Layout flex direction="column" flexGrow={1} height={vendorTask ? 48 : 64} gap={8}>
        <Text size="body-sm" maxLines={1}>
          {brand} {detail} {unit_type}s ({units_per_package}/{package_type})
        </Text>
        <Layout flex direction="row">
          {vendorTask ? (
            <Text size="body-sm" color="gray-400">
              SKU: {product.vendor_sku}
            </Text>
          ) : (
            <>
              {showQuantitySelector ? (
                quantity > 0 ? (
                  <Layout width={124}>
                    <AddQuantity
                      value={quantity}
                      showRemoveItemButton={quantity === 1}
                      minValue={1}
                      handleDecreaseQuantity={() => {
                        onQuantityChange?.(quantity - 1);
                      }}
                      handleIncreaseQuantity={() => {
                        onQuantityChange?.(quantity + 1);
                      }}
                      handleRemoveItem={() => {
                        // if the item has been saved, we add a key to the item called itemRemoved
                        if (isSaved) {
                          onQuantityChange?.(0);
                        } else {
                          removeItem?.();
                        }
                      }}
                      textAndIconColor="gray-900"
                      size="sm"
                    />
                  </Layout>
                ) : (
                  <TextButton
                    text="Add item back"
                    color="blue-500"
                    size="xs"
                    semibold={false}
                    textSize="body-sm"
                    onClick={() => {
                      onQuantityChange?.(1);
                    }}
                  />
                )
              ) : (
                <>
                  <Text size="body-sm" color="gray-700">
                    Qty: {quantity}
                  </Text>
                  <Layout marginX={8}>
                    <Text size="body-sm" color="gray-700">
                      •
                    </Text>
                  </Layout>
                </>
              )}
              <Layout marginLeft={showQuantitySelector ? 16 : 0} align="center" justify="center">
                <Text size="body-sm" color="gray-700">
                  {formattedAmount} ea.
                </Text>
              </Layout>
            </>
          )}
        </Layout>
      </Layout>
      {vendorTask ? (
        <Layout paddingX={12} height={48} marginLeft={12}>
          <Text size="body-sm" semibold>
            {quantity}
          </Text>
        </Layout>
      ) : (
        <Layout {...(isMobile ? { marginLeft: 12 } : { marginLeft: 48 })} height="100%" align="center" justify="center">
          <Text size="body-md" color="gray-700" semibold>
            {formatUsd(totalPrice / 100)}
          </Text>
        </Layout>
      )}
    </Layout>
  );
};

export default ConsumablesProductQuantityCard;
