import React, { PropsWithChildren } from 'react';

import { Animate, Img, Layout, Text } from 'ds';

interface Props {
  animateIn?: boolean;
  thinking?: boolean;
}

const ChatbotThreadSystemMessage: React.FC<PropsWithChildren<Props>> = ({ children, animateIn, thinking = false }) => {
  const content = (
    <Layout align="flex-end">
      <Layout width={40} height={40} minWidth={40} minHeight={40}>
        <Img srcKey={'new_version_Ivy_Icon_3d_2UmlF3Tey.png'} alt="thinking" width={400} height={400} folder="ux" />
      </Layout>
      <Layout
        color="white"
        paddingX={thinking ? undefined : 12}
        paddingY={thinking ? undefined : 12}
        marginLeft={12}
        borderTopRightRadius={16}
        borderBottomRightRadius={16}
        borderTopLeftRadius={16}
      >
        <Text size="body-sm">{children}</Text>
      </Layout>
    </Layout>
  );

  if (animateIn) {
    return <Animate>{content}</Animate>;
  }
  return content;
};

export default ChatbotThreadSystemMessage;
