import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  Building,
  CompactBuildingUnitWithCustomer,
  Listing,
  Message,
  PayoutAccount,
  SavedWorkspace,
  Updatable,
  User,
  VendorPayoutAccount,
  addOrUpdateInList,
  safeAssign,
  sortByDate,
  updateInList
} from 'shared';
import { VendorUser } from 'types';
import { AdminConsumablesProduct } from 'ux/Admin/AdminConsumables/types';
import { AdminListBuilding } from 'ux/Admin/Buildings/types';
import { AdminListOfficeLead, AdminListTour } from 'ux/Admin/Customer/Leads/types';
import { AdminDetailCustomer, AdminListCustomer, AdminListPlan, AdminListReferral } from 'ux/Admin/Customers/types';
import { AdminDetailLicensor } from 'ux/Admin/Licensors/Licensor/types';
import { AdminListLicensor } from 'ux/Admin/Licensors/types';
import { AdminProduct } from 'ux/Admin/Products/types';
import { AdminDetailShortTermBooking, AdminListShortTermBooking } from 'ux/Admin/ShortTermBookings/types';
import { AdminTask, AdminTaskListRow, AdminTaskScheduleListRow } from 'ux/Admin/Tasks/types';
import { AdminAvailableService } from 'ux/Admin/Tenants/Tenant/TenantInfo/types';
import { AdminTenancyList } from 'ux/Admin/Tenants/types';
import { AdminReferralConfig } from 'ux/Admin/Tools/AdminReferralConfigs/types';
import { FeatureFlag } from 'ux/Admin/Tools/Features/types';
import { ListSearchLocation } from 'ux/Admin/Tools/Locations/types';
import { AdminListUser } from 'ux/Admin/Users/types';
import { AdminDetailVendor } from 'ux/Admin/Vendors/Vendor/types';
import { AdminListVendor } from 'ux/Admin/Vendors/types';
import { AdminListWorkspace } from 'ux/Admin/Workspaces/types';
import { AdminStartupRequest } from 'ux/Admin/api_requests';
import { SupportTicket } from 'ux/Customer/Support/types';
import { WorkOrder } from 'ux/Tenant/types';

import { AdminSupportTicket, AdminSupportTicketPendingMessage } from './types';

interface ReduxListSearchLocation extends ListSearchLocation {
  id: string;
}

export type AdminState = {
  buildings: AdminListBuilding[];
  buildingUnits: CompactBuildingUnitWithCustomer[];
  building: Building | null;
  tenants: AdminTenancyList[];
  workspaces: AdminListWorkspace[];
  workspace: SavedWorkspace | null;
  users: AdminListUser[];
  licensors: AdminListLicensor[];
  licensor: AdminDetailLicensor | null;
  vendors: AdminListVendor[];
  vendorUsers: VendorUser[];
  vendor: AdminDetailVendor | null;
  user: User | null;
  customers: AdminListCustomer[];
  customer: AdminDetailCustomer | null;
  officeLeads: AdminListOfficeLead[];
  plans: AdminListPlan[];
  tours: AdminListTour[];
  products: AdminProduct[] | null;
  mapWorkspaces: Listing[];
  tasks: AdminTaskListRow[];
  taskSchedules: AdminTaskScheduleListRow[];
  shortTermBookings: AdminListShortTermBooking[];
  global: AdminStartupRequest | null;
  locations: ReduxListSearchLocation[];
  referrals: AdminListReferral[];
  referralConfigs: AdminReferralConfig[];
  featureFlags: FeatureFlag[];
  supportTickets: AdminSupportTicket[];
  availableServices: AdminAvailableService[];
  consumablesProducts: AdminConsumablesProduct[];
};

const initialState: AdminState = {
  buildings: [],
  building: null,
  buildingUnits: [],
  workspaces: [],
  mapWorkspaces: [],
  workspace: null,
  users: [],
  user: null,
  licensors: [],
  licensor: null,
  vendors: [],
  vendor: null,
  vendorUsers: [],
  customers: [],
  customer: null,
  officeLeads: [],
  plans: [],
  tours: [],
  products: [],
  tasks: [],
  taskSchedules: [],
  shortTermBookings: [],
  global: null,
  locations: [],
  referrals: [],
  referralConfigs: [],
  featureFlags: [],
  supportTickets: [],
  availableServices: [],
  consumablesProducts: [],
  tenants: []
};

const adminSlice = createSlice({
  name: 'Admin',
  initialState,
  reducers: {
    setBuildings: (state, { payload }: PayloadAction<AdminListBuilding[]>) => {
      state.buildings = payload;
    },
    setBuildingUnits: (state, { payload }: PayloadAction<CompactBuildingUnitWithCustomer[]>) => {
      state.buildingUnits = payload;
    },
    updateBuildingById: (state, { payload }: PayloadAction<Updatable<AdminListBuilding>>) => {
      updateInList(state.buildings, payload);
    },
    setWorkspaces: (state, { payload }: PayloadAction<AdminListWorkspace[]>) => {
      state.workspaces = payload;
    },
    setWorkspace: (state, { payload }: PayloadAction<SavedWorkspace | null>) => {
      state.workspace = payload;
    },
    updateWorkspace: (state, { payload }: PayloadAction<Partial<SavedWorkspace>>) => {
      safeAssign(state.workspace, payload);
    },
    updateWorkspaceById: (state, { payload }: PayloadAction<Updatable<AdminListWorkspace>>) => {
      updateInList(state.workspaces, payload);
    },
    setUsers: (state, { payload }: PayloadAction<AdminListUser[]>) => {
      state.users = payload;
    },
    updateUserById: (state, { payload }: PayloadAction<Updatable<AdminListUser>>) => {
      updateInList(state.users, payload);
    },
    updateListUser: (state, { payload }: PayloadAction<Updatable<AdminListUser>>) => {
      updateInList(state.users, payload);
    },
    setConsumablesProducts: (state, { payload }: PayloadAction<AdminConsumablesProduct[]>) => {
      state.consumablesProducts = payload;
    },
    updateConsumablesProductById: (state, { payload }: PayloadAction<Updatable<AdminConsumablesProduct>>) => {
      updateInList(state.consumablesProducts, payload);
    },
    setUser: (state, { payload }: PayloadAction<User | null>) => {
      state.user = payload;
    },
    updateUser: (state, { payload }: PayloadAction<Updatable<User>>) => {
      safeAssign(state.user, payload);
    },
    setLicensors: (state, { payload }: PayloadAction<AdminListLicensor[]>) => {
      state.licensors = payload;
    },
    setLicensor: (state, { payload }: PayloadAction<AdminDetailLicensor | null>) => {
      state.licensor = payload;
    },
    setLicensorPayoutAccount: (state, { payload }: PayloadAction<PayoutAccount>) => {
      if (!state.licensor) return;

      state.licensor.payout_account = payload;
    },
    setVendors: (state, { payload }: PayloadAction<AdminListVendor[]>) => {
      state.vendors = payload;
    },
    setVendor: (state, { payload }: PayloadAction<AdminDetailVendor | null>) => {
      state.vendor = payload;
    },
    setVendorUsers: (state, { payload }: PayloadAction<VendorUser[]>) => {
      state.vendorUsers = payload;
    },
    setVendorPayoutAccount: (state, { payload }: PayloadAction<VendorPayoutAccount>) => {
      if (!state.vendor) return;

      state.vendor.vendor_payout_account = payload;
    },
    setCustomers: (state, { payload }: PayloadAction<AdminListCustomer[]>) => {
      state.customers = payload;
    },
    setCustomer: (state, { payload }: PayloadAction<AdminDetailCustomer | null>) => {
      state.customer = payload;
    },
    setOfficeLeads: (state, { payload }: PayloadAction<AdminListOfficeLead[]>) => {
      state.officeLeads = payload;
    },
    setPlans: (state, { payload }: PayloadAction<AdminListPlan[]>) => {
      state.plans = payload;
    },
    setTours: (state, { payload }: PayloadAction<AdminListTour[]>) => {
      state.tours = payload;
    },
    setReferrals: (state, { payload }: PayloadAction<AdminListReferral[]>) => {
      state.referrals = payload;
    },
    setReferralConfigs: (state, { payload }: PayloadAction<AdminReferralConfig[]>) => {
      state.referralConfigs = payload;
    },
    updateReferralConfig: (state, { payload }: PayloadAction<Updatable<AdminReferralConfig>>) => {
      updateInList(state.referralConfigs, payload);
    },
    updateStb: (state, { payload }: PayloadAction<AdminDetailShortTermBooking>) => {
      if (!state.customer) return;

      const { short_term_bookings } = state.customer;

      updateInList(short_term_bookings, payload);
    },
    updateCustomerById: (state, { payload }: PayloadAction<Updatable<AdminListCustomer>>) => {
      updateInList(state.customers, payload);
    },
    setProducts: (state, { payload }: PayloadAction<AdminProduct[]>) => {
      state.products = payload;
    },
    updateProductById: (state, { payload }: PayloadAction<AdminProduct>) => {
      if (!state.products) {
        state.products = [payload];
      } else {
        updateInList(state.products || [], payload);
      }
    },
    setMapWorkspaces: (state, { payload }: PayloadAction<Listing[]>) => {
      state.mapWorkspaces = payload;
    },
    setTasks: (state, { payload }: PayloadAction<AdminTaskListRow[]>) => {
      state.tasks = payload;
    },
    addOrUpdateTaskInSupportTicket: (state, { payload }: PayloadAction<AdminTask>) => {
      const supportTicketIndex = state.supportTickets.findIndex(st => st.id === payload.support_ticket_id);

      if (supportTicketIndex === -1) return;

      state.supportTickets[supportTicketIndex].tasks.push(payload);
    },
    addOrUpdateTask: (state, { payload }: PayloadAction<AdminTaskListRow>) => {
      const existingTaskIndex = state.tasks.findIndex(r => r.id === payload.id);

      if (existingTaskIndex > -1) {
        state.tasks[existingTaskIndex] = payload;
      } else {
        state.tasks.push(payload);
      }
      state.tasks.sort((r1, r2) => sortByDate(r1.max_due_date, r2.max_due_date));
    },
    removeTask: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.tasks = state.tasks.filter(r => r.id !== payload.id);
    },
    addOrUpdateTaskSchedule: (state, { payload }: PayloadAction<AdminTaskScheduleListRow>) => {
      addOrUpdateInList(state.taskSchedules, payload);
    },
    setTaskSchedules: (state, { payload }: PayloadAction<AdminTaskScheduleListRow[]>) => {
      state.taskSchedules = payload;
    },
    setShortTermBookings: (state, { payload }: PayloadAction<AdminListShortTermBooking[]>) => {
      state.shortTermBookings = payload;
    },
    setGlobal: (state, { payload }: PayloadAction<AdminStartupRequest>) => {
      state.global = payload;
    },
    setLocations: (state, { payload }: PayloadAction<ListSearchLocation[]>) => {
      state.locations = payload.map(p => ({ ...p, id: p.guid }));
    },
    updateLocation: (state, { payload }: PayloadAction<Updatable<ReduxListSearchLocation>>) => {
      updateInList(state.locations, payload);
    },
    setFeatureFlags: (state, { payload }: PayloadAction<FeatureFlag[]>) => {
      state.featureFlags = payload;
    },
    setSupportTickets: (state, { payload }: PayloadAction<SupportTicket[]>) => {
      state.supportTickets = payload;
    },
    updateSupportTicket: (state, { payload }: PayloadAction<SupportTicket>) => {
      updateInList(state.supportTickets, payload);
    },
    addSupportTicket: (state, { payload }: PayloadAction<SupportTicket>) => {
      state.supportTickets.push(payload);
    },
    addSupportTicketMessage: (state, { payload }: PayloadAction<{ supportTicketId: number; message: Message }>) => {
      const supportTicketIndex = state.supportTickets.findIndex(ticket => ticket.id === payload.supportTicketId);

      if (supportTicketIndex === -1) return;

      state.supportTickets[supportTicketIndex].messages.push(payload.message);
    },
    setSupportTicketInput: (state, { payload }: PayloadAction<{ supportTicketId: number; input: string }>) => {
      const supportTicket = state.supportTickets.find(st => st.id === payload.supportTicketId);

      if (!supportTicket) return;

      supportTicket.input = payload.input;
    },
    addPendingSupportTicketMessage: (
      state,
      { payload }: PayloadAction<{ message: AdminSupportTicketPendingMessage; supportTicketId: number }>
    ) => {
      const supportTicket = state.supportTickets.find(st => st.id === payload.supportTicketId);

      if (!supportTicket) return;

      if (supportTicket.pendingMessages) {
        supportTicket.pendingMessages.push(payload.message);
      } else {
        supportTicket.pendingMessages = [payload.message];
      }
    },
    removePendingSupportTicketMessage: (
      state,
      { payload }: PayloadAction<{ messageId: number; supportTicketId: number }>
    ) => {
      const supportTicket = state.supportTickets.find(st => st.id === payload.supportTicketId);

      if (!supportTicket) return;

      supportTicket.pendingMessages = supportTicket.pendingMessages?.filter(
        message => message.id !== payload.messageId
      );
    },
    updateSupportTicketMessage: (state, { payload }: PayloadAction<Message>) => {
      const supportTickets = state.supportTickets;
      const supportTicketId = supportTickets.find(st => st.messages.some(m => m.id === payload.id))?.id;

      if (supportTicketId === undefined) return;

      const supportTicketIndex = state.supportTickets.findIndex(st => st.id === supportTicketId);

      if (supportTicketIndex === undefined) return;

      const messageIndex = state.supportTickets[supportTicketIndex].messages.findIndex(m => m.id === payload.id);

      if (messageIndex === undefined) return;

      state.supportTickets[supportTicketIndex].messages[messageIndex] = payload;
    },
    addWorkOrderToSupportTicket: (
      state,
      { payload }: PayloadAction<{ workOrder: WorkOrder; supportTicketId: number }>
    ) => {
      const supportTicket = state.supportTickets.find(st => st.id === payload.supportTicketId);

      if (!supportTicket) return;

      supportTicket.work_orders.push(payload.workOrder);
    },
    updateWorkOrderInSupportTicket: (
      state,
      { payload }: PayloadAction<{ workOrder: WorkOrder; supportTicketId: number }>
    ) => {
      const supportTicket = state.supportTickets.find(st => st.id === payload.supportTicketId);

      if (!supportTicket) return;

      const workOrderIndex = supportTicket.work_orders.findIndex(wo => wo.id === payload.workOrder.id);

      if (workOrderIndex === -1) return;

      supportTicket.work_orders[workOrderIndex] = payload.workOrder;
    },
    deleteWorkOrderFromSupportTicket: (
      state,
      { payload }: PayloadAction<{ workOrderId: number; supportTicketId: number }>
    ) => {
      const supportTicket = state.supportTickets.find(st => st.id === payload.supportTicketId);

      if (!supportTicket) return;

      supportTicket.work_orders = supportTicket.work_orders.filter(wo => wo.id !== payload.workOrderId);
    },
    setAvailableServices: (state, { payload }: PayloadAction<AdminAvailableService[]>) => {
      state.availableServices = payload;
    },
    setTenants: (state, { payload }: PayloadAction<AdminTenancyList[]>) => {
      state.tenants = payload;
    }
  }
});

export const { actions } = adminSlice;

export default adminSlice.reducer;
