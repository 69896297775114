import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { showSnackbar } from 'helpers/snackbar';

import { media } from 'context';
import { Layout, Link, PillButton, Text } from 'ds';
import PaymentMethodCard from 'ds/PaymentMethodCard';
import StripePaymentMethodModal from 'ds/StripePaymentMethodModal';
import { getDefaultPaymentMethod } from 'ds/StripePaymentMethodModal/requests';
import { PANTRY_SUBSCRIPTION_PATH } from 'routes/paths';
import { actions } from 'store/Consumables';
import { actions as tenancyActions } from 'store/Tenancy';
import { selectDefaultPaymentMethod } from 'store/Tenancy/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import { createInitialOnboardingOrder } from '../requests';

const ConsumablesPaymentMethod: React.FC = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);
  const [loading, setLoading] = useState(false);
  const defaultPaymentMethod = useAppSelector(selectDefaultPaymentMethod);
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const [paymentMethodModalOrdinal, setPaymentMethodModalOrdinal] = useState(0);
  const { isMobile } = useContext(media);

  useEffect(() => {
    loadDefaultPaymentMethod();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadDefaultPaymentMethod = () => {
    getDefaultPaymentMethod().then(({ data }) => {
      dispatch(tenancyActions.setDefaultPaymentMethod(data));
    });
  };

  const closePaymentMethodModal = () => {
    setShowPaymentMethodModal(false);
    setPaymentMethodModalOrdinal(paymentMethodModalOrdinal + 1);
  };

  if (!user) return null;

  const handleStartService = async () => {
    try {
      const createInitialOnboardingOrderResponse = await createInitialOnboardingOrder();
      if (createInitialOnboardingOrderResponse.status !== 200) {
        showSnackbar({
          message: 'Failed to start service. Please try again.',
          negative: true
        });
        return;
      } else {
        dispatch(
          actions.setConsumablesProductSubscriptions(
            createInitialOnboardingOrderResponse.data.consumables_product_subscriptions
          )
        );
        dispatch(actions.setConsumablesOrders(createInitialOnboardingOrderResponse.data.consumables_orders));
        history.push(PANTRY_SUBSCRIPTION_PATH);
        showSnackbar({
          message: 'Service started',
          position: 'left'
        });
      }
    } catch (err) {
      showSnackbar({
        message: 'An unexpected error occurred. Please try again.',
        negative: true
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout direction="column" gap={24}>
      <StripePaymentMethodModal
        key={paymentMethodModalOrdinal}
        isVisible={showPaymentMethodModal}
        onUpdate={loadDefaultPaymentMethod}
        onClose={closePaymentMethodModal}
      />
      <PaymentMethodCard
        onClick={() => setShowPaymentMethodModal(true)}
        onUpdate={() => setShowPaymentMethodModal(true)}
      />
      <Layout {...(isMobile ? {} : { width: 272 })}>
        <PillButton
          fullWidth
          size="lg"
          type="purpleGradient"
          text="Start service"
          disabled={loading || !defaultPaymentMethod}
          showSpinner={loading}
          borderRadius={40}
          onClick={handleStartService}
        />
      </Layout>
      <Layout marginTop={12} paddingTop={24} borderTop>
        <Text size="body-xs" color="gray-400">
          Your payment method will be charged twice monthly, on the Monday before the 2nd and 4th Fridays, for the items
          ordered as part of your subscription. You authorize charges for future orders. Prices are subject to change as
          per Codi’s{' '}
          <Link href="/terms">
            <Text color="gray-400" size="body-xs" underline inline __style={{ textUnderlineOffset: '2px' }}>
              Terms of Service
            </Text>
          </Link>
          . You may cancel at any time, subject to the refund policy detailed in Codi’s{' '}
          <Link href="/terms#managed-services">
            <Text color="gray-400" size="body-xs" underline inline __style={{ textUnderlineOffset: '2px' }}>
              Managed Services Terms
            </Text>
          </Link>
          . By clicking 'Start Service', you acknowledge that you have read, understood, and agreed to Codi’s{' '}
          <Link href="/terms#managed-services">
            <Text color="gray-400" size="body-xs" underline inline __style={{ textUnderlineOffset: '2px' }}>
              Managed Services Terms
            </Text>
          </Link>
          , which are incorporated into Codi’s{' '}
          <Link href="/terms">
            <Text color="gray-400" size="body-xs" underline inline __style={{ textUnderlineOffset: '2px' }}>
              Terms of Service
            </Text>
          </Link>
          .
        </Text>
      </Layout>
    </Layout>
  );
};

export default ConsumablesPaymentMethod;
