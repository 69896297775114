import { ProductKey } from 'shared';

export const LOCAL_STORAGE_NAMESPACE = '__codi__';
export const HELP_URL = 'https://help.codi.com';
export const DEFAULT_META_DESCRIPTION =
  "Codi is the most flexible office space platform that adapts to your team's hybrid work needs as you grow. With our customizable plans, you get to choose your locations, amenities, and schedule, ensuring your workspaces adapt to you.";
export const REQUEST_READY = 'ready';
export const REQUEST_IN_PROGRESS = 'in_progress';
export const REQUEST_SUCCEEDED = 'succeeded';
export const REQUEST_FAILED = 'failed';
export const DEEP_CLEANING: ProductKey = 'deep_cleaning';
export const PHONE_BOOTH: ProductKey = 'phone_booth';

export const CLEANING_PRODUCT_KEYS: ProductKey[] = [DEEP_CLEANING];
export const WEEKLY_CLEANING: ProductKey = 'weekly_cleaning';
export const WEEKLY_TOUCH_UP: ProductKey = 'weekly_touch_up';
export const NON_FURNITURE_PRODUCT_KEYS = [DEEP_CLEANING, PHONE_BOOTH];
