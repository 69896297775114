import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';

import { media } from 'context';
import Layout, { Props as LayoutProps } from 'ds/Layout';

interface Props extends LayoutProps {}

export const StickyContainer: React.FC<PropsWithChildren<Props>> = ({ children, ...props }) => {
  const [stickyTop, setStickyTop] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useContext(media);

  useEffect(() => {
    if (ref.current) {
      setStickyTop(ref.current.getBoundingClientRect().top);
    }
  }, [isMobile]);

  return (
    <Layout position="sticky" top={stickyTop} __ref={ref} {...props}>
      {children}
    </Layout>
  );
};
