import { Animate } from 'ds';

interface Props {
  children: React.ReactNode;
}

const AnimateMessage: React.FC<Props> = ({ children }) => {
  return (
    <Animate visible={true} animation="fade-up" duration={300} delay={20}>
      {children}
    </Animate>
  );
};

export default AnimateMessage;
