import React, { useContext, useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon';

import { showSnackbar } from 'helpers/snackbar';

import { app, media } from 'context';
import { Animate, Layout, ScrollShadowContainer, Text } from 'ds';
import usePageVisibility from 'hooks/usePageVisibility';
import { actions } from 'store/AIAssistant';
import { selectChatbotThreadById, selectPendingThreadInteraction } from 'store/AIAssistant/selectors';
import { selectUser } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { createOrUpdateSupportTicketView } from 'ux/Customer/Support/requests';

import ChatbotThinkingDisplay from './ChatbotThinkingDisplay';
import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import ChatbotThreadMessage from './ChatbotThreadMessage';
import { addFeedbackToLlmMessage } from './requests';
import { getGreetingVariation } from './utils';

interface ChatbotThreadProps {
  threadId: number;
  onThreadsRendered?: () => void;
}

const THREAD_ID_TO_GREETING_VARIATION = new Map<number, string>();

const ChatbotThread: React.FC<ChatbotThreadProps> = ({ threadId, onThreadsRendered }) => {
  const user = useAppSelector(selectUser);
  const parsedThreadId = threadId || -1;
  const thread = useAppSelector(selectChatbotThreadById(parsedThreadId));
  const { isMobile } = useContext(media);
  const { appPaddingX, windowWidth } = useContext(app);
  let greetingVariation = THREAD_ID_TO_GREETING_VARIATION.get(parsedThreadId);
  if (!greetingVariation) {
    greetingVariation = getGreetingVariation(user?.firstname || '');
    THREAD_ID_TO_GREETING_VARIATION.set(parsedThreadId, greetingVariation);
  }

  const pendingInteraction = useAppSelector(selectPendingThreadInteraction(parsedThreadId));
  const lastInteraction = thread?.chatbot_interactions.slice(-1)[0];
  const [_hasScrolled, setHasScrolled] = useState(false);

  const supportTicket = lastInteraction?.chatbot_intents.map(ci => ci.support_ticket).find(st => st?.id);

  const handleSupportTicketView = async () => {
    if (supportTicket?.id) {
      const { data } = await createOrUpdateSupportTicketView({ supportTicketId: supportTicket.id });

      dispatch(actions.updateSupportTicket(data));
    }
  };

  usePageVisibility(isVisible => {
    if (isVisible) {
      handleSupportTicketView();
    }
  });

  useEffect(() => {
    if (!supportTicket?.id) return;

    handleSupportTicketView();
  }, [supportTicket?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      if (threadId === -1) {
        THREAD_ID_TO_GREETING_VARIATION.delete(threadId);
      }
    };
  }, [threadId]);

  const dispatch = useAppDispatch();
  const llmMessagesOrdered = useMemo(
    () =>
      thread?.chatbot_interactions
        .flatMap(interaction =>
          (interaction.llm_conversation?.llm_messages || []).map(message => ({
            ...message,
            chatbotInteraction: interaction
          }))
        )
        .sort((a, b) => DateTime.fromISO(a.created_at).toMillis() - DateTime.fromISO(b.created_at).toMillis()) || [],
    [thread?.chatbot_interactions]
  );

  useEffect(() => {
    if (llmMessagesOrdered.length > 0 && onThreadsRendered) {
      onThreadsRendered();
    }
  }, [llmMessagesOrdered, onThreadsRendered]);

  if (!user) return null;

  const handleAddFeedback = async (llmMessageId: number, chatbotInteractionId: number, liked: boolean) => {
    const response = await addFeedbackToLlmMessage(llmMessageId, liked);
    if (response.status === 200) {
      showSnackbar({
        message: 'Thanks for the feedback!',
        type: 'light'
      });
      dispatch(actions.updateLlmMessageFeedback({ llmMessageId, threadId, interactionId: chatbotInteractionId }));
    } else {
      showSnackbar({
        message: 'Failed to add feedback',
        negative: true
      });
    }
  };

  return (
    <ScrollShadowContainer
      setHasScrolled={setHasScrolled}
      position="relative"
      {...(isMobile
        ? {
            paddingX: appPaddingX,
            paddingBottom: 100,
            paddingTop: 60,
            width: windowWidth,
            minHeight: window.innerHeight
          }
        : { width: 768, paddingX: 24, paddingBottom: 24 })}
      height="100%"
    >
      <Layout flex direction="column" justify="flex-end" minHeight="100%">
        {!!thread?.created_at && (
          <Animate
            animation="fade-up"
            visible={true}
            duration={300}
            delay={20}
            justify="center"
            keyframes={[
              {
                transform: 'translateY(20px)'
              },
              {
                transform: 'translateY(0)'
              }
            ]}
          >
            <Layout justify="center" align="center" paddingY={12}>
              <Layout borderRadius={1000} color="gray-25" paddingX={12} paddingY={2} flexShrink={1}>
                <Text size="body-sm">{DateTime.fromISO(thread?.created_at).toFormat('MMM d, h:mm a')}</Text>
              </Layout>
            </Layout>
          </Animate>
        )}
        {llmMessagesOrdered.map(message => {
          return (
            <ChatbotThreadMessage
              key={message.id}
              message={message}
              handleAddFeedback={handleAddFeedback}
              threadId={threadId}
            />
          );
        })}
        {!!pendingInteraction && (
          <Animate marginBottom={24} animation="fade-up" duration={300} marginLeft={24} delay={0} justify="flex-end">
            <ChatbotThreadCustomerMessage>{pendingInteraction.input}</ChatbotThreadCustomerMessage>
          </Animate>
        )}
        {!!pendingInteraction && (
          <Animate marginTop={24} delay={300} duration={300}>
            <ChatbotThinkingDisplay />
          </Animate>
        )}
      </Layout>
    </ScrollShadowContainer>
  );
};

export default ChatbotThread;
