import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Clickable, Icon, Layout, Text } from 'ds';
import { AI_ASSISTANT_PATH } from 'routes';
import { actions } from 'store/AIAssistant';

import { determineIntent, startThread } from './requests';

interface ChatbotPromptSuggestionCardProps {
  prompt: string;
  setIsExitAnimationActive: (showExitAnimation: boolean) => void;
}

const ChatbotPromptSuggestionCard: React.FC<ChatbotPromptSuggestionCardProps> = ({
  prompt,
  setIsExitAnimationActive
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const handlePromptCardClick = async (input: string) => {
    setIsExitAnimationActive(true);
    setTimeout(async () => {
      const { data } = await startThread();
      dispatch(actions.addThread(data));
      dispatch(actions.addPendingThreadInteraction({ threadId: data.id, input }));
      history.push(`${AI_ASSISTANT_PATH}/${data.id}`);
      const { data: interaction } = await determineIntent({
        chatbotThreadId: data.id,
        message: prompt,
        intentGroup: 'tenancy_customers'
      });
      dispatch(actions.removePendingThreadInteraction({ threadId: data.id }));
      dispatch(actions.addInteraction({ interaction, threadId: data.id }));
    }, 700);
  };

  return (
    <Clickable onClick={() => handlePromptCardClick(prompt)} style={{ height: 'fit-content' }}>
      <Layout
        height={40}
        width="fit-content"
        borderRadius={1000}
        color="purple-gradient-transparent"
        paddingY={8}
        paddingLeft={12}
        paddingRight={20}
        hoverColor="gray-25"
      >
        <Layout flex direction="row" gap={4} align="center">
          <Icon name="sparkle" size={24} color="purple-gradient" />
          <Text size="body-sm" semibold color="purple-gradient">
            {prompt}
          </Text>
        </Layout>
      </Layout>
    </Clickable>
  );
};

export default ChatbotPromptSuggestionCard;
