import React, { useContext, useState } from 'react';
import { DateTime } from 'luxon';

import { media } from 'context';
import { IconButton } from 'ds';
import { Layout } from 'ds';

import AnimateMessage from './AnimateMessage';
import ChatbotThreadCustomerMessage from './ChatbotThreadCustomerMessage';
import ChatbotThreadSystemMessage from './ChatbotThreadSystemMessage';
import ChatbotViewSupportTicket from './ChatbotWidgets/ChatbotViewSupportTicket';
import ChatbotWidget from './ChatbotWidgets/ChatbotWidget';
import { LLMMessageInteraction } from './types';

interface Props {
  message: LLMMessageInteraction;
  handleAddFeedback: (messageId: number, chatbotInteractionId: number, liked: boolean) => void;
  threadId: number;
}

const ChatbotThreadMessage: React.FC<Props> = ({ message, handleAddFeedback, threadId }) => {
  const [messageIdHovered, setMessageIdHovered] = useState<number | null>(null);
  const isMessageNew = DateTime.fromISO(message.created_at).diffNow().toMillis() > -5000;
  const { isMobile } = useContext(media);

  if (message.role === 'user') {
    return (
      <Layout marginLeft={24} justify="flex-end">
        <ChatbotThreadCustomerMessage id={`user-message-${message.id}`}>
          <div dangerouslySetInnerHTML={{ __html: message.content }} />
        </ChatbotThreadCustomerMessage>
      </Layout>
    );
  } else if (message.role === 'assistant') {
    const assistantMessageContent = (
      <div onMouseOver={() => setMessageIdHovered(message.id)} onMouseLeave={() => setMessageIdHovered(null)}>
        <Layout marginTop={16} flex direction="column">
          <ChatbotThreadSystemMessage>
            <div dangerouslySetInnerHTML={{ __html: message.content }} />
          </ChatbotThreadSystemMessage>
          {!message.has_given_feedback && (
            <Layout
              flex
              direction="row"
              marginLeft={64}
              gap={2}
              visibility={isMobile || messageIdHovered === message.id ? 'visible' : 'hidden'}
            >
              <IconButton
                name="thumbsUp"
                type="noBackground"
                size="sm"
                stroke={2}
                onClick={() => handleAddFeedback(message.id, message.chatbotInteraction.id, true)}
              />
              <IconButton
                name="thumbsDown"
                type="noBackground"
                size="sm"
                stroke={2}
                onClick={() => handleAddFeedback(message.id, message.chatbotInteraction.id, false)}
              />
            </Layout>
          )}
        </Layout>
      </div>
    );
    if (isMessageNew) return <AnimateMessage>{assistantMessageContent}</AnimateMessage>;
    return assistantMessageContent;
  } else if (message.role === 'metadata' && message.metadata.resolution?.type === 'support_ticket') {
    const supportTicketMessageContent = (
      <Layout marginTop={16}>
        <ChatbotViewSupportTicket supportTicket={message.metadata.resolution?.support_ticket} />
      </Layout>
    );

    if (isMessageNew) return <AnimateMessage>{supportTicketMessageContent}</AnimateMessage>;
    return supportTicketMessageContent;
  } else if (message.role === 'metadata' && message.metadata.resolution?.type === 'widget') {
    return (
      <Layout marginTop={16}>
        <ChatbotWidget
          chatbotInteraction={message.chatbotInteraction}
          threadId={threadId}
          chatbotIntentId={message.chatbot_intent_id}
        />
      </Layout>
    );
  }
};

export default ChatbotThreadMessage;
