import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';

import { showSnackbar } from 'helpers/snackbar';

import { ANIMATION_DURATION, Icon, IconButton, Layout, StickyContainer, Text } from 'ds';
import { PANTRY_ORDERS_PATH } from 'routes/paths';
import { ConsumablesOrder, ConsumablesProduct, DraftConsumablesOrderItem, formatDate } from 'shared';
import { actions } from 'store/Consumables';
import { selectConsumablesOrders } from 'store/Consumables/selectors';
import { selectUIState } from 'store/UI/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ConsumablesOrderFeedbackModal from './ConsumablesOrderFeedbackModal';
import OrderProgressBar from './OrderProgressBar';
import { getOrderStatus } from './utils';
import ConsumablesAddProductsComboBox from '../ConsumablesAddProductsComboBox';
import ConsumablesProductQuantityCard from '../ConsumablesProductQuantityCard';
import CostSummaryBox from '../CostSummaryBox';
import {
  getConsumablesProductCategories,
  getConsumablesProductTypes,
  getConsumablesProducts,
  updateConsumablesOrderItems
} from '../requests';
import { getConsumablesProductSubscriptions } from '../requests';
import { getConsumablesOrders } from '../requests';

const ConsumablesOrderPage = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { prevPathname } = useAppSelector(selectUIState);
  const containerRef = useRef<HTMLDivElement>(null);
  const [top, setTop] = useState(0);
  const [liked, setLiked] = useState<boolean | null>(null);
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false);
  const consumablesOrders = useAppSelector(selectConsumablesOrders);
  const consumablesOrder = consumablesOrders?.find((order: ConsumablesOrder) => order.id === parseInt(id));
  const [clonedConsumablesOrderItems, setClonedConsumablesOrderItems] = useState<DraftConsumablesOrderItem[]>([
    ...(consumablesOrder?.consumables_order_items || [])
  ]);

  useEffect(() => {
    if (!consumablesOrders?.length) {
      Promise.all([
        getConsumablesProducts(),
        getConsumablesProductCategories(),
        getConsumablesProductTypes(),
        getConsumablesOrders(),
        getConsumablesProductSubscriptions()
      ]).then(
        ([
          { data: products },
          { data: newCategories },
          { data: newTypes },
          { data: orders },
          { data: subscriptions }
        ]) => {
          dispatch(actions.setProducts(products));
          dispatch(actions.setCategories(newCategories));
          dispatch(actions.setTypes(newTypes));
          dispatch(actions.setConsumablesOrders(orders));
          const order = orders.find(order => order.id === parseInt(id));
          setClonedConsumablesOrderItems([...(order?.consumables_order_items || [])]);
          dispatch(actions.setConsumablesProductSubscriptions(subscriptions));
          dispatch(actions.setConsumablesStateLoaded(true));
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  if (!consumablesOrder) {
    return null;
  }

  const itemsSubtotal = clonedConsumablesOrderItems.reduce(
    (acc, curr) => acc + curr.quantity * curr.consumables_product.amount_cents,
    0
  );

  // Build an object mapping consumables_product.id to order_quantity
  const originalObj =
    consumablesOrder?.consumables_order_items?.reduce((acc, item) => {
      acc[item.consumables_product.id] = item.quantity;
      return acc;
    }, {} as Record<number, number>) || {};

  const clonedObj = clonedConsumablesOrderItems.reduce((acc, item) => {
    acc[item.consumables_product.id] = item.quantity;
    return acc;
  }, {} as Record<number, number>);

  const changesMade = (() => {
    const originalKeys = Object.keys(originalObj);
    const clonedKeys = Object.keys(clonedObj);

    if (originalKeys.length !== clonedKeys.length) return true;

    for (const key of originalKeys) {
      const numericKey = Number(key);
      if (originalObj[numericKey] !== clonedObj[numericKey]) return true;
    }

    return false;
  })();

  const orderStatus = getOrderStatus(consumablesOrder);
  const itemsRestocked = consumablesOrder.consumables_order_items.reduce((acc, item) => acc + item.quantity, 0);
  const prevPathnameSubscription = prevPathname?.endsWith('subscription');
  const prevPathnameShop = prevPathname?.includes('shop');
  const backButtonHref =
    (prevPathnameSubscription || prevPathnameShop) && prevPathname ? prevPathname : PANTRY_ORDERS_PATH;
  return (
    <Layout flex direction="column" width="100%" height="100%">
      <StickyContainer zIndex={1000}>
        <Layout
          width="100%"
          height={88}
          paddingX={40}
          paddingY={24}
          background="white"
          borderBottom
          borderColor="gray-50"
        >
          <IconButton size="lg" type="noBackground" name="leftArrow" href={backButtonHref} />
        </Layout>
      </StickyContainer>
      <Layout paddingX={80} paddingTop={40} paddingBottom={64} flex direction="row" gap={80} justify="center">
        <Layout
          flex
          direction="column"
          gap={36}
          width={724}
          __ref={containerRef}
          onMeasure={() => setTop(containerRef.current?.getBoundingClientRect().top || 0)}
        >
          <Layout direction="column" gap={12}>
            {orderStatus === 'open' || orderStatus === 'locked' ? (
              <>
                <Text size="headline-lg">
                  Next restocking on{' '}
                  {formatDate({ date: consumablesOrder.delivery_date, format: 'MED_WITH_SHORT_WEEKDAY' })}
                </Text>
                <Text size="body-lg" __style={{ letterSpacing: '-0.01em' }}>
                  The items will be unpacked and organized in your preferred pantry locations.
                </Text>
              </>
            ) : (
              <>
                <Layout flex direction="row" gap={8} align="center">
                  <Layout
                    width={32}
                    height={32}
                    justify="center"
                    align="center"
                    borderRadius="circular"
                    color="green-700"
                  >
                    <Icon name="checkmark" size="sm" color="white" />
                  </Layout>
                  <Text size="headline-lg" color="green-700">
                    Restocking on{' '}
                    {formatDate({ date: consumablesOrder.delivery_date, format: 'MED_WITH_SHORT_WEEKDAY' })}
                  </Text>
                </Layout>
                <Text size="body-lg">{itemsRestocked} items were restocked</Text>
              </>
            )}
          </Layout>
          {(orderStatus === 'open' ||
            orderStatus === 'locked' ||
            (orderStatus === 'delivered' && !consumablesOrder.has_given_feedback)) && (
            <Layout direction="column" gap={12}>
              {(orderStatus === 'open' || orderStatus === 'locked') && (
                <>
                  <OrderProgressBar status={orderStatus} />
                  <Text size="body-sm" color="gray-400">
                    {orderStatus === 'open' ? 'Order open' : 'Preparing items'}
                  </Text>
                </>
              )}
              {orderStatus === 'delivered' && !consumablesOrder.has_given_feedback && (
                <Layout flex color="gray-25" borderRadius={16} paddingX={24} paddingY={12} gap={24} align="center">
                  <Text size="body-md" semibold>
                    How’d it go?
                  </Text>
                  <Layout flex direction="row" gap={16}>
                    <IconButton
                      size="lg"
                      stroke={2}
                      type="gray"
                      name="thumbsUp"
                      onClick={() => {
                        setIsFeedbackModalVisible(true);
                        setLiked(true);
                      }}
                    />
                    <IconButton
                      size="lg"
                      stroke={2}
                      type="gray"
                      name="thumbsDown"
                      onClick={() => {
                        setIsFeedbackModalVisible(true);
                        setLiked(false);
                      }}
                    />
                  </Layout>
                </Layout>
              )}
            </Layout>
          )}

          <Layout direction="column" gap={12}>
            <Text size="headline-xs">{orderStatus === 'open' ? 'Edit items in order' : 'Order summary'}</Text>
            {orderStatus === 'open' && (
              <Layout
                color="gold-200"
                borderRadius={8}
                align="center"
                paddingX={12}
                paddingY={8}
                flex
                direction="row"
                gap={8}
              >
                <Icon name="info" size="xs" />
                <Text size="body-sm" color="gray-900" semibold>
                  You can edit or add items to this order through{' '}
                  {formatDate({
                    date: DateTime.fromISO(consumablesOrder.delivery_date).minus({ days: 5 }).toISO(),
                    format: 'MED'
                  })}
                  .
                </Text>
              </Layout>
            )}
          </Layout>
          {orderStatus === 'open' && (
            <ConsumablesAddProductsComboBox
              existingProductSelections={clonedConsumablesOrderItems}
              onSelect={(product: ConsumablesProduct | undefined) => {
                if (product) {
                  if (!clonedConsumablesOrderItems.some(p => p.consumables_product.id === product.id)) {
                    setClonedConsumablesOrderItems(prev => [
                      {
                        consumables_product: product,
                        quantity: 1,
                        consumables_order_id: consumablesOrder.id
                      },
                      ...prev
                    ]);
                  }
                }
              }}
            />
          )}
          <Layout flex direction="column" gap={36}>
            {clonedConsumablesOrderItems.map(productOrderItem => (
              <ConsumablesProductQuantityCard
                isSaved={productOrderItem.id !== undefined}
                showQuantitySelector={orderStatus === 'open'}
                removeItem={() => {
                  setClonedConsumablesOrderItems(prev =>
                    prev.filter(p => p.consumables_product.id !== productOrderItem.consumables_product.id)
                  );
                }}
                key={productOrderItem.consumables_product.id}
                product={productOrderItem.consumables_product}
                quantity={productOrderItem.quantity}
                onQuantityChange={quantity => {
                  setClonedConsumablesOrderItems(prev =>
                    prev.map(p =>
                      p.consumables_product.id === productOrderItem.consumables_product.id ? { ...p, quantity } : p
                    )
                  );
                }}
              />
            ))}
            {orderStatus !== 'open' && (
              <Layout borderTop borderColor="gray-50">
                <CostSummaryBox sticky top={top} itemsSubtotalCents={itemsSubtotal} paddingX={0} />
              </Layout>
            )}
          </Layout>
        </Layout>
        {orderStatus === 'open' && (
          <Layout width={320}>
            <CostSummaryBox
              sticky
              top={top}
              itemsSubtotalCents={itemsSubtotal}
              disclaimerMessage={
                <Text size="body-xs" color="gray-700">
                  Next charge on{' '}
                  {formatDate({ date: consumablesOrder.delivery_date, format: 'MED_WITH_SHORT_WEEKDAY' })}
                </Text>
              }
              changesMade={changesMade}
              onResetChanges={() => {
                setClonedConsumablesOrderItems([...(consumablesOrder?.consumables_order_items || [])]);
              }}
              onClickSaveChanges={async () => {
                try {
                  const response = await updateConsumablesOrderItems(consumablesOrder.id, clonedConsumablesOrderItems);
                  if (response.data.message === 'Cannot update order - a newer order exists.') {
                    showSnackbar({
                      message: 'Current order is locked. Please see open order for changes.',
                      negative: true
                    });
                    dispatch(
                      actions.setConsumablesProductSubscriptions(
                        response.data.service.consumables_product_subscriptions
                      )
                    );
                    dispatch(actions.setConsumablesOrders(response.data.service.consumables_orders));
                    setClonedConsumablesOrderItems(
                      response.data.service.consumables_orders.find(
                        (order: ConsumablesOrder) => order.id === parseInt(id)
                      )?.consumables_order_items || []
                    );
                    return;
                  } else if (response.status === 200) {
                    dispatch(
                      actions.setConsumablesProductSubscriptions(response.data.consumables_product_subscriptions)
                    );
                    dispatch(actions.setConsumablesOrders(response.data.consumables_orders));
                    // reset cloned consumables order items to the order items from the response that matches the id in url
                    setClonedConsumablesOrderItems(
                      response.data.consumables_orders.find((order: ConsumablesOrder) => order.id === parseInt(id))
                        ?.consumables_order_items || []
                    );
                    showSnackbar({
                      message: 'Upcoming order updated'
                    });
                  }
                } catch (error) {
                  showSnackbar({
                    message: 'Something went wrong with updating the order items',
                    negative: true
                  });
                }
              }}
              backgroundColor="gray-25"
            />
          </Layout>
        )}
      </Layout>
      <ConsumablesOrderFeedbackModal
        isVisible={isFeedbackModalVisible}
        onClose={() => {
          setIsFeedbackModalVisible(false);
          setTimeout(() => {
            setLiked(null);
          }, ANIMATION_DURATION);
        }}
        feedbackRequest={{
          task_id: consumablesOrder.task_id as number,
          type: 'restocking',
          date: consumablesOrder.delivery_date ?? '',
          action: null
        }}
        feedback={{
          task_id: consumablesOrder.task_id as number,
          liked: liked ?? false,
          message: null
        }}
        selectedOrder={consumablesOrder}
      />
    </Layout>
  );
};

export default ConsumablesOrderPage;
