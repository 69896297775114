export const TENANT_WELCOME_NUX_ACTION = 'tenant_welcome_nux_finished';
export const KICKOFF_MODAL_ACTION = 'kickoff_modal_viewed';
export const FURNITURE_SERVICE_STARTED_ACTION = 'tenant_furniture_service_started';
export const SUGGESTED_CHATBOT_PROMPTS = [
  'A light burned out',
  'The AC isn’t working',
  'Order a standing desk',
  'Kitchen sink is leaking',
  'Order more access cards',
  'Schedule a cleaning',
  'Rent a phone booth',
  'We need more trash bags',
  "We're out of oat milk",
  'Order more sparkling water',
  'We need more coffee',
  'Internet is down'
];
