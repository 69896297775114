import { FocusEventHandler, forwardRef, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { media } from 'context';
import Animate from 'ds/Animate';
import IconButton from 'ds/IconButton';
import Layout from 'ds/Layout';
import { selectInputFocusOrdinal } from 'store/AIAssistant/selectors';

interface Props {
  handleSubmit: () => void;
  disabled: boolean;
  input: string;
  onChange: (value: string) => void;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  disableAnimation?: boolean;
  isExitAnimationActive?: boolean;
}

interface RouteParams {
  id?: string;
}

const ChatbotThreadInput = forwardRef<HTMLInputElement, Props>(
  ({ onChange, input, handleSubmit, onFocus, onBlur, disableAnimation, isExitAnimationActive }, ref) => {
    const params = useParams<RouteParams>();
    const threadId = params.id ? parseInt(params.id) : undefined;
    const inputFocusOrdinal = useSelector(selectInputFocusOrdinal);
    const { isMobile } = useContext(media);

    useEffect(() => {
      if (inputFocusOrdinal === 0) return;

      if (ref && 'current' in ref) {
        ref.current?.focus();
      }
    }, [inputFocusOrdinal]); // eslint-disable-line react-hooks/exhaustive-deps

    const Content = (
      <Layout align="center" borderRadius={1000} {...(isMobile ? { gap: 12 } : {})}>
        {isMobile && (
          <Layout>
            <IconButton size="sm" type="primaryBlue" name="upArrow" onClick={handleSubmit} stroke={2} />
          </Layout>
        )}
        <input
          className="Chatbot-threadInput"
          autoFocus={threadId !== undefined ? true : false}
          value={input}
          onChange={e => onChange(e.target.value)}
          onKeyDown={({ key }) => {
            if (ref && 'current' in ref) {
              if (document.activeElement !== ref.current) {
                return;
              }
            }

            if (!input) return;

            if (key === 'Enter') {
              handleSubmit();
            }
          }}
          onFocus={onFocus}
          autoComplete="off"
          ref={ref}
          spellCheck={false}
          placeholder={threadId !== undefined ? 'Send a message...' : 'Ask me about your office...'}
          onBlur={onBlur}
        />
        {!isMobile && (
          <Layout width={768} position="absolute" align="center">
            <Layout position="absolute" right={12}>
              <IconButton size="sm" type="primaryBlue" name="upArrow" onClick={handleSubmit} stroke={2} />
            </Layout>
          </Layout>
        )}
      </Layout>
    );

    return (
      <Animate
        duration={isExitAnimationActive ? 400 : 300}
        animation={isExitAnimationActive ? 'fade-down' : 'fade-up'}
        disableAnimation={disableAnimation}
        delay={isExitAnimationActive ? 300 : 20}
      >
        {Content}
      </Animate>
    );
  }
);

export default ChatbotThreadInput;
