import React, { PropsWithChildren } from 'react';

import { Layout, Text } from 'ds';

import { CHATBOT_THREAD_VERTICAL_SPACING } from './constants';

interface Props {
  id?: string;
}

const ChatbotThreadCustomerMessage: React.FC<PropsWithChildren<Props>> = ({ children, id }) => {
  return (
    <Layout
      id={id}
      color="blue-500"
      paddingX={12}
      paddingY={12}
      borderBottomLeftRadius={16}
      borderTopLeftRadius={16}
      borderTopRightRadius={16}
      marginTop={CHATBOT_THREAD_VERTICAL_SPACING}
    >
      <Text size="body-sm" color="white">
        {children}
      </Text>
    </Layout>
  );
};

export default ChatbotThreadCustomerMessage;
