import React, { Suspense, useContext, useEffect } from 'react';
import { Redirect, RouteComponentProps, matchPath } from 'react-router-dom';
import { compact } from 'lodash';
import qs from 'qs';

import { DEFAULT_META_DESCRIPTION } from 'helpers/constants';

import Head from 'components/Head';

import { media } from 'context';
import { actions as uiActions } from 'store/UI';
import { selectSelectedOfficeId, selectUIState, selectUxType } from 'store/UI/selectors';
import {
  selectCustomerAdminPlans,
  selectCustomerAdminWorkspaceIds,
  selectCustomerOfficePaths,
  selectHasListingAdminRole,
  selectHasPayoutAdminRole,
  selectHasPayoutViewerRole,
  selectHasPropertiesNavItem,
  selectHasSignedPlans,
  selectIsAdmin,
  selectIsCustomerUser,
  selectIsProspect,
  selectOffices,
  selectReservationWorkspaces,
  selectSelectedOfficeInCustomerAdminOffices,
  selectSwitcheableWorkspaceIds,
  selectUser,
  selectUserHasOffsites
} from 'store/User/selectors';
import { selectVendor } from 'store/Vendor/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserV2 } from 'store/v2/User/selectors';
import { selectHasPlanAdminType, selectHasVendorAdminType, selectHasVendorUserTypes } from 'store/v2/User/selectors';
import { isTenantAdminUser } from 'store/v2/User/utils';
import BottomNavigation from 'ux/Layouts/Shared/Navigation/BottomNavigation';
import CustomerBanner from 'ux/Layouts/Shared/Navigation/CustomerBanner';
import NavigationContainer from 'ux/Layouts/Shared/Navigation/NavigationContainer';
import Sidebar from 'ux/Layouts/Shared/Navigation/Sidebar';
import TenantBanner from 'ux/Layouts/Shared/Navigation/TenantBanner';
import {
  AI_ASSISTANT_NAV_ITEM,
  CLEANING_SERVICE_NAV_ITEM,
  CUSTOMER_SUPPORT_NAV_ITEM,
  DASHBOARD_NAV_ITEM,
  FAVORITES_NAV_ITEM,
  FURNITURE_AND_EQUIPMENT_NAV_ITEM,
  HOME_NAV_ITEM,
  NAV_ITEM_ORDER,
  OFFICE_SEARCH_NAV_ITEM,
  OFFSITES_NAV_ITEM,
  PANTRY_AND_SUPPLIES_NAV_ITEM,
  PROPERTIES_ACCOUNTS_NAV_ITEM,
  PROPERTIES_LISTINGS_NAV_ITEM,
  PROPERTIES_PAYOUTS_NAV_ITEM,
  SERVICES_AND_AMENITIES_NAV_ITEM,
  TENANT_ADMIN_NAV_ITEM,
  VENDOR_SCHEDULES_NAV_ITEM,
  VENDOR_TASKS_ITEM,
  VENDOR_TEAM_NAV_ITEM
} from 'ux/Layouts/Shared/Navigation/constants';
import {
  getAdminBottomNavItems,
  getBottomNavItems,
  getMobileAccountItems,
  getMoreNavItems
} from 'ux/Layouts/Shared/utils';

import Page from './Page';
import { ADMIN_PATH, AUTH_PATH, PROPERTIES_PATH } from './paths';
import { Route } from './types';
import { defaultLoginRedirectPath, validUserForRole } from './utils';

interface Props
  extends RouteComponentProps,
    Pick<
      Route,
      'requiredRole' | 'redirect' | 'scrollToTop' | 'customLayout' | 'sideBarIsExpandable' | 'showMobileBottomNav'
    > {
  children: JSX.Element;
  isReady: boolean;
}

const AuthenticatedRoute: React.FC<Props> = ({
  children,
  requiredRole,
  redirect,
  location: { pathname, search },
  scrollToTop,
  isReady,
  customLayout,
  sideBarIsExpandable,
  showMobileBottomNav = true
}) => {
  const user = useAppSelector(selectUser);
  const userV2 = useAppSelector(selectUserV2);
  const { isMobile } = useContext(media);
  const vendor = useAppSelector(selectVendor);
  const hasVendorUserTypes = useAppSelector(selectHasVendorUserTypes);
  const hasVendorAdminType = useAppSelector(selectHasVendorAdminType);
  const isVendor = !!vendor;
  const isVendorAdmin = !!vendor?.admin;
  const hasListingRole = useAppSelector(selectHasListingAdminRole);
  const hasPayoutAdminRole = useAppSelector(selectHasPayoutAdminRole);
  const hasPayoutViewerRole = useAppSelector(selectHasPayoutViewerRole);
  const hasPlanAdminType = useAppSelector(selectHasPlanAdminType);
  const hasSignedPlans = useAppSelector(selectHasSignedPlans);
  const reservationWorkspaces = useAppSelector(selectReservationWorkspaces);
  const { logout } = qs.parse(search, { ignoreQueryPrefix: true });
  const reduxUxType = useAppSelector(selectUIState).uxType;
  const reduxIsPropertyUX = reduxUxType === 'property';
  const isPropertyUX = pathname.startsWith(PROPERTIES_PATH) || reduxIsPropertyUX;
  const isAdminUx = pathname.startsWith(ADMIN_PATH) || reduxUxType === 'admin';
  const isCustomerUx = reduxUxType === 'customer';
  const isTenantUx = reduxUxType === 'tenant';
  const isTenantAdmin = !!(userV2 && isTenantUx && isTenantAdminUser(userV2));
  const hasPropertiesNavItem = useAppSelector(selectHasPropertiesNavItem);
  const offices = useAppSelector(selectOffices);
  const hasOffices = !!offices.length;
  const hasOffsites = useAppSelector(selectUserHasOffsites);
  const isCustomerUser = useAppSelector(selectIsCustomerUser);
  const isProspect = useAppSelector(selectIsProspect);
  const selectedOfficeId = useAppSelector(selectSelectedOfficeId);
  const customerAdminPlans = useAppSelector(selectCustomerAdminPlans);
  const hasActivePlans = !!customerAdminPlans?.long_term_plans.filter(p => !!p.active_workspace_id).length;
  const switcheableWorkspaceIds = useAppSelector(selectSwitcheableWorkspaceIds);
  const selectedOfficeInCustomerAdminOffices = useAppSelector(selectSelectedOfficeInCustomerAdminOffices);
  const customerAdminWorkspaceIds = useAppSelector(selectCustomerAdminWorkspaceIds);
  const isAdmin = useAppSelector(selectIsAdmin);
  const customerOfficePaths = useAppSelector(selectCustomerOfficePaths);
  const uxType = useAppSelector(selectUxType);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!user?.id || !userV2?.id) return;

    let matchedWorkspaceIds = customerOfficePaths.map(p => {
      const match = matchPath(pathname, { path: p.officePath, exact: true });

      if (!match) return null;

      // @ts-expect-error
      const workspaceId = Number(match.params.workspaceId);

      if (isNaN(workspaceId)) return null;

      return workspaceId;
    });

    matchedWorkspaceIds = compact(matchedWorkspaceIds);

    let matchedOfficeId: number | undefined | null = matchedWorkspaceIds[0];

    if (matchedOfficeId === selectedOfficeId) return;

    // SelectedOfficeId is persisted and may be mismatched in QA
    if (selectedOfficeId && customerAdminWorkspaceIds.length && !customerAdminWorkspaceIds.includes(selectedOfficeId)) {
      dispatch(uiActions.setSelectedOfficeId(customerAdminWorkspaceIds[0]));
    }

    if (typeof matchedOfficeId !== 'number') {
      if (customerAdminPlans?.active_workspace_ids?.length) {
        matchedOfficeId = customerAdminPlans.active_workspace_ids[0];
      } else {
        matchedOfficeId = reservationWorkspaces[0]?.id;
      }
    }

    if (!matchedOfficeId || !switcheableWorkspaceIds.includes(matchedOfficeId)) return;

    dispatch(uiActions.setSelectedOfficeId(matchedOfficeId));
  }, [isReady, user?.id, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isReady) return null;

  if (requiredRole && (user === null || userV2 === null)) {
    const pathnameWithSearch = `${pathname}${search}`;
    const toPath = logout ? AUTH_PATH : `${AUTH_PATH}?redirect=${encodeURIComponent(pathnameWithSearch)}`;
    return <Redirect to={toPath} />;
  } else if (requiredRole && user && userV2 && !validUserForRole({ user, userV2, role: requiredRole, uxType })) {
    return <Redirect to={redirect || defaultLoginRedirectPath(user)} />;
  }

  const sideBarNavItems = [];

  if (isPropertyUX) {
    if (hasPropertiesNavItem) {
      sideBarNavItems.push(PROPERTIES_LISTINGS_NAV_ITEM);
    }

    if (hasPayoutAdminRole) {
      sideBarNavItems.push(PROPERTIES_ACCOUNTS_NAV_ITEM);
    }

    if (hasPayoutAdminRole || hasPayoutViewerRole) {
      sideBarNavItems.push(PROPERTIES_PAYOUTS_NAV_ITEM);
    }
  } else if (hasVendorUserTypes) {
    sideBarNavItems.push(VENDOR_TASKS_ITEM);
    if (hasVendorAdminType) {
      sideBarNavItems.push(VENDOR_TEAM_NAV_ITEM);
      sideBarNavItems.push(VENDOR_SCHEDULES_NAV_ITEM);
    }
  } else if (isTenantUx && isTenantAdmin) {
    sideBarNavItems.push(AI_ASSISTANT_NAV_ITEM);
    sideBarNavItems.push(CLEANING_SERVICE_NAV_ITEM);
    sideBarNavItems.push(PANTRY_AND_SUPPLIES_NAV_ITEM);
    sideBarNavItems.push(FURNITURE_AND_EQUIPMENT_NAV_ITEM);
    sideBarNavItems.push(CUSTOMER_SUPPORT_NAV_ITEM);
    sideBarNavItems.push(TENANT_ADMIN_NAV_ITEM);
  } else if (isTenantUx) {
    sideBarNavItems.push(HOME_NAV_ITEM);
  } else {
    sideBarNavItems.push(DASHBOARD_NAV_ITEM);

    if (isProspect) {
      sideBarNavItems.push(FAVORITES_NAV_ITEM);
    } else if (!hasOffices) {
      sideBarNavItems.push(OFFICE_SEARCH_NAV_ITEM);
    }

    if (hasPlanAdminType && hasSignedPlans && selectedOfficeInCustomerAdminOffices) {
      sideBarNavItems.push(CUSTOMER_SUPPORT_NAV_ITEM);
      sideBarNavItems.push(SERVICES_AND_AMENITIES_NAV_ITEM);
    }

    if (hasOffsites) {
      sideBarNavItems.push(OFFSITES_NAV_ITEM);
    }
  }

  const mobileItemsToLimit = uxType === 'tenant' ? 4 : 3;

  const bottomNavItems = isAdminUx
    ? getAdminBottomNavItems()
    : getBottomNavItems({
        isTenantUx,
        isTenantAdmin,
        isVendorAdmin,
        hasPlanAdminType,
        hasPayoutAdminRole,
        hasPayoutViewerRole,
        isPropertyUX,
        isVendor,
        hasListingRole,
        hasOffices,
        hasOffsites,
        isCustomerUser,
        isProspect,
        hasSignedPlans,
        selectedOfficeInCustomerAdminOffices,
        user: user || null,
        hasActivePlans,
        itemsToLimit: mobileItemsToLimit
      });

  const moreNavItems = getMoreNavItems({
    isTenantUx,
    isTenantAdmin,
    hasPlanAdminType,
    hasPayoutAdminRole,
    hasPayoutViewerRole,
    isPropertyUX,
    isVendor,
    isVendorAdmin,
    hasListingRole,
    hasOffices,
    hasOffsites,
    isCustomerUser,
    isProspect,
    hasSignedPlans,
    selectedOfficeInCustomerAdminOffices,
    user: user || null,
    hasActivePlans,
    itemsToLimit: mobileItemsToLimit
  });

  const navItemOrderType = isTenantUx ? 'tenant' : 'other';
  sideBarNavItems.sort(
    (item1, item2) =>
      NAV_ITEM_ORDER[navItemOrderType].indexOf(item1.label) - NAV_ITEM_ORDER[navItemOrderType].indexOf(item2.label)
  );
  const accountItems = getMobileAccountItems({
    hasActivePlans,
    hasPropertiesNavItem,
    isAdmin,
    isTenantUx
  });

  return (
    <>
      <Head title="Codi" description={DEFAULT_META_DESCRIPTION} />
      <Page scrollToTop={scrollToTop} requiredRole={requiredRole}>
        {pathname.startsWith(ADMIN_PATH) ? (
          children
        ) : (
          <>
            {isTenantUx && isTenantAdmin && <TenantBanner />}
            {isCustomerUx && isCustomerUser && <CustomerBanner />}
            <NavigationContainer>
              {!customLayout && (
                <>
                  {isMobile ? (
                    showMobileBottomNav && (
                      <BottomNavigation
                        items={bottomNavItems}
                        theme="light"
                        moreNavItems={[...moreNavItems, ...accountItems]}
                      />
                    )
                  ) : (
                    <Sidebar
                      theme="white"
                      items={sideBarNavItems}
                      avatarUrl={user?.avatar_url}
                      isExpandable={sideBarIsExpandable}
                    />
                  )}
                </>
              )}
              <Suspense fallback={null}>{children}</Suspense>
            </NavigationContainer>
          </>
        )}
      </Page>
    </>
  );
};

export default AuthenticatedRoute;
