import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import qs from 'qs';

import { showSnackbar } from 'helpers/snackbar';

import { app, media } from 'context';
import { APP_BANNER_HEIGHT, HEADER_Z_INDEX, IconButton, Layout, Text } from 'ds';
import { APP_PAGE_PADDING_X_MOBILE } from 'ds/PageLayout/constants';
import { createUserAction } from 'shared/requests';
import { actions } from 'store/AIAssistant';
import { selectThreadsSidebarVisible } from 'store/AIAssistant/selectors';
import { selectIsTenantUx, selectUIState } from 'store/UI/selectors';
import { actions as userActions } from 'store/User';
import { selectUser, selectUserActions } from 'store/User/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import ChatbotPromptSuggestionCard from './ChatbotPromptSuggestionCard';
import ChatbotThread from './ChatbotThread';
import ChatbotThreads from './ChatbotThreads';
import CustomerChatbotThreadInput from './CustomerChatbotThreadInput';
import KickoffModal from './KickoffModal';
import { fetchThreads } from './requests';
import { SUGGESTED_CHATBOT_PROMPTS } from '../constants';
import { KICKOFF_MODAL_ACTION } from '../constants';

interface RouteParams {
  id?: string;
}

const AIAssistant: React.FC = () => {
  const dispatch = useAppDispatch();
  const { appBannerType } = useAppSelector(selectUIState);
  const { isMobile } = useContext(media);
  const { bottomNavigationHeight } = useContext(app);
  const history = useHistory();
  const user = useAppSelector(selectUser);
  const isTenantUx = useAppSelector(selectIsTenantUx);
  const params = useParams<RouteParams>();
  const threadId = params.id ? parseInt(params.id) : undefined;
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const { windowWidth } = useContext(app);
  // TODO: do we want a randomly generated greeting?
  const [greeting, _setGreeting] = useState('What can I help with today?');
  const isThreadsDrawerVisible = useAppSelector(selectThreadsSidebarVisible);
  const [sideBarIsAlreadyShown, setSideBarIsAlreadyShown] = useState(isThreadsDrawerVisible);
  const [showSuggestedPrompts, setShowSuggestedPrompts] = useState(false);
  const qsParams = qs.parse(history.location.search, { ignoreQueryPrefix: true });
  const hasKickoffParam = 'kickoff' in qsParams;

  const existingUserActions = useAppSelector(selectUserActions);
  const viewedKickoffModal = existingUserActions.find(a => a.value === KICKOFF_MODAL_ACTION);

  const [showKickoffModal, setShowKickoffModal] = useState<boolean>(false);
  const [areThreadsRendered, setAreThreadsRendered] = useState(false);
  const [isExitAnimationActive, setIsExitAnimationActive] = useState(false);
  const inputLayoutRef = useRef<HTMLDivElement>(null);
  const [inputLayoutHeight, setInputLayoutHeight] = useState(0);
  const chatbotInputContainerMarginTop = 36;
  const chatbotInputContainerMarginBottom = threadId ? 36 : 0;
  const topPageOffset = 88;

  const [suggestedPrompts, setSuggestedPrompts] = useState<string[]>([]);

  useEffect(() => {
    const initialPrompts = SUGGESTED_CHATBOT_PROMPTS.sort(() => Math.random() - 0.5).slice(0, 3);
    setSuggestedPrompts(initialPrompts);
  }, []);

  useEffect(() => {
    if (hasKickoffParam && !viewedKickoffModal) {
      setShowKickoffModal(true);
    }
  }, [hasKickoffParam, viewedKickoffModal]);

  useEffect(() => {
    const setThreads = () =>
      fetchThreads()
        .then(({ data }) => {
          dispatch(actions.setThreads(data));
        })
        .catch(() => {
          showSnackbar({ message: 'Uh oh! There was an error connecting. Please try again later.', negative: true });
        });

    setThreads();
  }, [dispatch]);

  useEffect(() => {
    if (user?.id && showKickoffModal) {
      createUserAction({ userId: user?.id, value: KICKOFF_MODAL_ACTION }).then(({ data }) => {
        dispatch(userActions.updateUser({ user_actions: data }));
      });
    }
  }, [showKickoffModal, dispatch, user?.id]);

  const handleThreadsRendered = () => {
    setAreThreadsRendered(true);
  };

  useEffect(() => {
    if (!threadId || !areThreadsRendered) return;

    if (isMobile) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'instant'
        });
      }, 100);
    } else {
      const container = document.getElementById('chatbot-thread-container');
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'instant'
        });
      }
    }
  }, [threadId, areThreadsRendered, isMobile]);

  useEffect(() => {
    if (threadId && inputLayoutRef.current) {
      const height = inputLayoutRef.current.getBoundingClientRect().height;
      setInputLayoutHeight(height + chatbotInputContainerMarginTop + chatbotInputContainerMarginBottom);
    } else {
      setInputLayoutHeight(0);
    }
  }, [threadId, chatbotInputContainerMarginTop, chatbotInputContainerMarginBottom]);

  if (!user) {
    return null;
  }

  if (!isTenantUx && !process.env.STORYBOOK) return null;

  if (isMobile) {
    return (
      <Layout
        marginTop={appBannerType ? APP_BANNER_HEIGHT : 0}
        direction="row"
        justify="center"
        align="center"
        className={isThreadsDrawerVisible ? 'content-shift' : 'content-reset'}
      >
        <KickoffModal isVisible={showKickoffModal} onClose={() => setShowKickoffModal(false)} />
        {!isThreadsDrawerVisible && (
          <Layout
            position="fixed"
            left={0}
            top={0}
            paddingLeft={24}
            paddingRight={8}
            paddingTop={24}
            paddingBottom={12}
            color="white"
            zIndex={HEADER_Z_INDEX}
          >
            <IconButton
              iconColor="gray-400"
              name="sideMenu"
              stroke={2}
              size="md"
              onClick={() => dispatch(actions.setSidebarVisible(true))}
              type="noBackground"
            />
          </Layout>
        )}
        <ChatbotThreads
          sideBarIsAlreadyShown={sideBarIsAlreadyShown}
          isVisible={isThreadsDrawerVisible}
          onClose={() => {
            setSideBarIsAlreadyShown(false);
            dispatch(actions.setSidebarVisible(false));
          }}
          loadedFirstTime={loadedFirstTime}
          setLoadedFirstTime={setLoadedFirstTime}
        />
        <Layout
          direction="column"
          justify="center"
          align="center"
          overflow="hidden"
          className={classNames('AIAssistantThread', { 'is-threads-drawer-visible': isThreadsDrawerVisible })}
        >
          {threadId && <ChatbotThread threadId={threadId} onThreadsRendered={handleThreadsRendered} />}
          {!threadId && (
            <Layout direction="column" justify="center" align="center" paddingX={APP_PAGE_PADDING_X_MOBILE}>
              <Text size="body-xl" bold align="center">
                Hi, {user?.firstname}.
              </Text>
              <Text size="body-xl" bold color="purple-gradient">
                {greeting}
              </Text>
            </Layout>
          )}
          <Layout
            position="fixed"
            bottom={bottomNavigationHeight}
            paddingY={12}
            left={0}
            width={windowWidth}
            paddingX={APP_PAGE_PADDING_X_MOBILE}
            color="white"
          >
            <CustomerChatbotThreadInput threadId={threadId} />
          </Layout>
        </Layout>
      </Layout>
    );
  }

  const handleThreadInputFocus = () => {
    if (showSuggestedPrompts || threadId !== undefined) return;
    setShowSuggestedPrompts(true);
  };

  return (
    <Layout
      width="100%"
      marginTop={appBannerType ? APP_BANNER_HEIGHT : 0}
      direction="row"
      justify="center"
      align="center"
      className={isThreadsDrawerVisible ? 'content-shift' : 'content-reset'}
    >
      <KickoffModal isVisible={showKickoffModal} onClose={() => setShowKickoffModal(false)} />
      {!isThreadsDrawerVisible && (
        <Layout position="absolute" top={24 + (appBannerType ? APP_BANNER_HEIGHT : 0)} left={84}>
          <IconButton
            iconColor="gray-400"
            name="sideMenu"
            stroke={2}
            size="md"
            onClick={() => dispatch(actions.setSidebarVisible(true))}
            type="noBackground"
          />
        </Layout>
      )}
      <ChatbotThreads
        sideBarIsAlreadyShown={sideBarIsAlreadyShown}
        isVisible={isThreadsDrawerVisible}
        onClose={() => {
          setSideBarIsAlreadyShown(false);
          dispatch(actions.setSidebarVisible(false));
        }}
        loadedFirstTime={loadedFirstTime}
        setLoadedFirstTime={setLoadedFirstTime}
      />
      <Layout direction="column" width="100%" justify={threadId ? 'flex-end' : 'center'} align="center" height="100%">
        {threadId && (
          <Layout
            id="chatbot-thread-container"
            width="100%"
            maxHeight={
              appBannerType
                ? `calc(100vh - ${inputLayoutHeight}px - ${APP_BANNER_HEIGHT}px - ${topPageOffset}px)`
                : `calc(100vh - ${inputLayoutHeight}px - ${topPageOffset}px)`
            }
            flex
            justify="center"
            align="flex-start"
            overflowY="auto"
          >
            <ChatbotThread threadId={threadId} onThreadsRendered={handleThreadsRendered} />
          </Layout>
        )}
        {!threadId && (
          <Layout
            direction="column"
            width="100%"
            justify="center"
            align="center"
            className={`greeting-layout ${isExitAnimationActive ? 'exit' : ''}`}
          >
            <Text size="body-xl" bold align="center">
              Hi, {user?.firstname}.
            </Text>
            <Text size="body-xl" bold color="purple-gradient">
              {greeting}
            </Text>
          </Layout>
        )}
        <Layout
          marginTop={chatbotInputContainerMarginTop}
          width={768}
          maxWidth={768}
          marginBottom={chatbotInputContainerMarginBottom}
          __ref={inputLayoutRef}
          position={threadId === undefined ? 'relative' : undefined}
        >
          <CustomerChatbotThreadInput
            threadId={threadId}
            setIsExitAnimationActive={setIsExitAnimationActive}
            isExitAnimationActive={isExitAnimationActive}
            onThreadInputFocus={handleThreadInputFocus}
          />
          {threadId === undefined && (
            <Layout
              opacity={0}
              className={
                showSuggestedPrompts && !isExitAnimationActive
                  ? 'prompt-suggestions-entrance-animation'
                  : isExitAnimationActive
                  ? 'prompt-suggestions-exit-animation'
                  : ''
              }
              position="absolute"
              bottom={
                -(inputLayoutRef.current?.getBoundingClientRect().height !== undefined
                  ? inputLayoutRef.current?.getBoundingClientRect().height + 24
                  : 80)
              }
              left={0}
              width="100%"
              height="100%"
              flex
              direction="row"
              gap={8}
              justify="center"
            >
              {suggestedPrompts.map(prompt => (
                <ChatbotPromptSuggestionCard
                  key={prompt}
                  prompt={prompt}
                  setIsExitAnimationActive={setIsExitAnimationActive}
                />
              ))}
            </Layout>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default AIAssistant;
