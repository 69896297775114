import React from 'react';

import { Layout } from 'ds';
import thinkingGif from 'shared/assets/Thinking.gif';

import ChatbotThreadSystemMessage from './ChatbotThreadSystemMessage';

interface Props {}

const ChatbotThinkingDisplay: React.FC<Props> = () => {
  return (
    <ChatbotThreadSystemMessage thinking>
      <Layout flex>
        <img src={thinkingGif} alt="AI is thinking" width={40} height={40} />
      </Layout>
    </ChatbotThreadSystemMessage>
  );
};

export default ChatbotThinkingDisplay;
